import React from "react";
import "./App.css";

const App = () => (
  <main>
    <div className="first">
      <h1>
        Michael Sparks
        <a
          href="https://www.linkedin.com/in/michael-sparks-83902013/"
          target="_blank"
        >
          LinkedIn ↗
        </a>
      </h1>
      <p>
        I'm an engineering manager living in Richmond, VA. Currently at Yahoo.
        Formerly Verzion, Aol, HuffPo, WaPo.
      </p>
    </div>
  </main>
);

export default App;
